import React from "react"
import { graphql } from "gatsby"

import NotFoundOrFormSubmittedLayout from "../components/NotFoundOrFormSubmittedLayout"
import Layout from '../components/Layout'

import img404 from '../images/404.gif'

const NotFoundPage = ({ data }) => {

  const { allWpMenu: {menus}, allWp: { nodes } } = data
  const { acfOptionsOptionsPage: { optionsPage: { copyrightField } } } = nodes[0]

  const mainMenu = menus.filter(menu => menu.locations[0] === 'MENU_1');
  const footerMenu = menus.filter(menu => menu.locations[0] === 'FOOTER_MENU');

  return (
    <Layout mainMenu={mainMenu} footerMenu={footerMenu} copyrightField={copyrightField} title="404: Not found">
      <NotFoundOrFormSubmittedLayout
        classes="page404" 
        copyright={copyrightField}
        imageSrc={img404}
        imageAlt="Page not found"
        title="No results"
        text="Oopsy daisy… Apparently the page that you are looking for does not exist." 
      />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allWp {
      nodes {
        acfOptionsOptionsPage {
          optionsPage {
            copyrightField
          }
        }
      }
    }
    allWpMenu {
      ...getMenus
    }
  }
`
