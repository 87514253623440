import React from 'react'
import PropTypes from 'prop-types'

import CustomNavLink from './CustomNavLink'
import PageHeroSideElements from './PageHeroSideElements'
import CtaButton from './CtaButton'

import './NotFoundOrFormSubmittedLayout.scss'

/**
 * NotFoundOrFormSubmittedLayout component
 * 
 * @param {String} copyright 
 * @param {String} imageSrc 
 * @param {String} imageAlt 
 * @param {String} title 
 * @param {String} text 
 * @param {String} classes 
 * 
 * @returns {JSX Element}
 * 
 */
export default function NotFoundOrFormSubmittedLayout({ copyright, imageSrc, imageAlt, title, text, classes }) {

  return (
    <div className={`${classes}`}>
      <div className="content-wrapper">
        <div className={`${classes}__wrapper`}>
          <div className={`${classes}__image-wrapper`}>
            <img src={imageSrc} alt={imageAlt} />
          </div>
          <div className={`${classes}__content`}>
            <h1 className={`${classes}__title`}>{title}</h1>
            <p className={`${classes}__text`}>{text}</p>
            <CustomNavLink
              path='/'
              classes={`${classes}__link`}
            >
              <CtaButton 
                ctaText="Return home"
                classModifier="arrow"
              />
            </CustomNavLink>
          </div>
        </div>
      </div>
      <PageHeroSideElements copyright={copyright} />
    </div>
  )
}

NotFoundOrFormSubmittedLayout.propTypes = {
  copyright: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  classes: PropTypes.string,
}
